import { AppAppointmentContext } from './AppointmentContext';
import { AuthProvider, ProtectRoute } from './AuthContext';
import { AppCompanyContext } from './CompanyContext';
import { AppUserMeContext } from './UserMeContext';

const AppContext = ({ children }: { children: React.ReactNode }) => (
  <AuthProvider>
    <ProtectRoute>
      <AppUserMeContext>
        <AppCompanyContext>
          <AppAppointmentContext>{children}</AppAppointmentContext>
        </AppCompanyContext>
      </AppUserMeContext>
    </ProtectRoute>
  </AuthProvider>
);
export default AppContext;
