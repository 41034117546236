import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<NavigationMenuInterface> = [
    {
      id: 'visitorLog',
      icon: 'usergroup',
      iconSize: 'micro',
      title: t('menu:visitor'),
      children: [
        {
          id: 'visitorLog.view',
          title: t('menu:visitorLog'),
          navLink: '/visitor/log'
        }
      ]
    },
    {
      id: 'appointment',
      icon: 'calendarSchedule',
      iconSize: 'micro',
      title: t('menu:appointment'),
      children: [
        {
          id: 'appointment.view',
          title: t('menu:appointment'),
          navLink: '/appointment/list'
        }
      ]
    }
  ];

  return { navigationConfig };
};
