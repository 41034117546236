import {
  AppointmentContextInterface,
  FormikAppointmentInterface
} from '@interface/AppointmentInterface';
import { createContext, useContext, useState } from 'react';

export const initVisitorData: FormikAppointmentInterface = {
  name: '',
  company: '',
  photo: '',
  file: null,
  appoinmentId: '',
  phone: '',
  email: '',
  reason: '',
  appointmentDate: '',
  appointmentTime: ''
};

export const AppointmentContext = createContext<AppointmentContextInterface>({
  isShowModal: false,
  isError: false,
  errorMessage: '',
  visitorData: initVisitorData,
  setVisitorData: () => undefined,
  setIsShowModal: () => undefined,
  setIsError: () => undefined,
  resetData: () => undefined,
  setErrorMessage: () => undefined
});

export const AppointmentContextProvider = AppointmentContext.Provider;
export const AppointmentContextConsumer = AppointmentContext.Consumer;
AppointmentContext.displayName = 'Appointment Context';

export const AppAppointmentContext = ({ children }: { children: React.ReactNode }) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [visitorData, setVisitorData] = useState<FormikAppointmentInterface>(initVisitorData);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const resetData = () => {
    setVisitorData(initVisitorData);
    setIsError(false);
  };

  return (
    <AppointmentContextProvider
      value={{
        isShowModal,
        visitorData,
        isError,
        errorMessage,
        setErrorMessage,
        setIsShowModal,
        resetData,
        setIsError,
        setVisitorData
      }}
    >
      {children}
    </AppointmentContextProvider>
  );
};

export const useAppointmentContext = () => useContext(AppointmentContext);
